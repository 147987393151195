/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate){
          func.apply(context, args);
        }
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow){
        func.apply(context, args);
      }
    };
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
      }
    },
    // Home page
    'home': {
      init: function() {

        $('#menutrigger').popover({
          html: true,
          placement: function(){
            if($('.navbar').hasClass('affix-top')){
              return 'top';
            } else {
              return 'bottom';
            }
          },
          trigger: 'click focus',
          content: function(){
            return $('#menu').html();
          }
        });

        $('#menutrigger').on('shown.bs.popover', function () {

          $('.menulink').on('click', function(event) {

              console.log('triggered');

              var $anchor = $(this);

              $('html, body').stop().animate({

                  scrollTop: $($anchor.attr('href')).offset().top + -50

              }, 1500);

              $('#menutrigger').popover('hide');
              event.preventDefault();
          });

        });

        $('#scroll-indicator').on('click', function(event) {

          $('html, body').stop().animate({

              scrollTop: $('main.main').offset().top + -50

          }, 750);

          event.preventDefault();
        });

      },
      finalize: function() {

        document.onreadystatechange = function () {

          if(document.readyState === "complete"){

            $('.headerchecker').on('visible invisible',function(e){

              // Decide whether to enable or disable the visible class.
              $('header').toggleClass('above_fold',e.type==='visible');
              $('header').toggleClass('below_fold',e.type==='invisible');

              // Output visibility to the console.
              // console.log('header is ' + e.type);

            });

            $(window).on('scroll resize', debounce(function(e){

              var $headerImg = $('.homepage-header'),
                  headerHeight = $headerImg.outerHeight(true),
                  $headerchecker = $('.headerchecker'),
                  dataname  = 'visibility',
                  visible   = 'visible',
                  invisible = 'invisible';

              if(e.type === "resize"){

                $headerImg.parent('.homepage-header').css('min-height', headerHeight);
                $headerImg.addClass('fixed');

              }

              $(window).off('.affix');
              $('header>nav').removeData('bs.affix').removeClass('affix affix-top affix-bottom');
              $('header>nav').affix({
                offset: {
                  top: headerHeight
                }
              });

              // Is the element visible?
              if( $headerchecker.visible(true) ) {
                // Have we already triggerd the visible event?
                if( $headerchecker.data(dataname) !== visible ) {
                  // if not... do it now.
                  $headerchecker.trigger(visible);
                  $headerchecker.data(dataname,visible);
                }
              } else {
                // Have we already triggered the invisible event?
                if( $headerchecker.data(dataname) !== invisible ) {
                  // if not... do it now.
                  $headerchecker.trigger(invisible);
                  $headerchecker.data(dataname,invisible);
                }
              }
            }, 5)).trigger('resize');

          }
        };

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
